import React from 'react'

export const getNumWorkDays =(startDate, endDate)=>{
   
  var numWorkDays = 0;
  const theDate = new Date(startDate)
  const theEnd =  new Date(endDate)
  while (theDate <= theEnd){

       // Skips Sunday and Saturday
       if (theDate.getDay() !== 0 && theDate.getDay() !== 6) {
          numWorkDays++;
      }
      theDate.setDate(theDate.getDate()+1)
  }
  return numWorkDays 
}

export const getCAPoint=(arms)=>{
  let head=40;
  let exam = 60;
  switch(arms) {
      case 'Creche':
       head = 60;
       exam = 40;
        break;
      case 'Pre-Nursery':
          head = 60;
          exam = 40;
        break;
        case 'Nursery':
       head = 60;
       exam = 40;
        break;
        case 'Primary':
       head = 40;
       exam = 60;
        break;
      case 'Junior Secondary School':
          head = 40;
          exam = 60;
        break;
        case 'Senior Secondary School':
          head = 30;
          exam = 70;
        break;
      default:
          head = 40;
          exam = 60;
    }
return {ca:head, exam:exam}
  }


  export const getPosition=(arms)=>{
    var head = 'Position';
  
    switch(arms) {
        case 'Creche':
         head = 'Pre-Nursery';
          break;
        case 'Pre-Nursery':
            head = 'Pre-Nursery';
          break;
          case 'Nursery':
         head = 'Nursery';
          break;
          case 'Primary':
         head = 'Position';
          break;
        case 'Junior Secondary School':
            head = 'Position';
          break;
          case 'Senior Secondary School':
            head = 'Position';
          break;
        default:
            head = 'Position';
      }
  
      return head;
  }


export const getResultHead=(arms)=>{
  var head = 'Secondary';

  switch(arms) {
      case 'Creche':
       head = 'Pre-Nursery';
        break;
      case 'Pre-Nursery':
          head = 'Pre-Nursery';
        break;
        case 'Nursery':
       head = 'Nursery';
        break;
        case 'Primary':
       head = 'Primary';
        break;
      case 'Junior Secondary School':
          head = 'Secondary';
        break;
        case 'Senior Secondary School':
          head = 'Secondary';
        break;
      default:
          head = 'Secondary';
    }

    return head;
}



export const getClassField=(classID, schoolClass, fieldName)=>{
  let  answer = schoolClass&&schoolClass.filter(list=>list.ID===classID)
  const result = answer.map(c=>c[fieldName])
return result
}

export const getClassName=(classID, sectionID, schoolClass)=>{
  let  answer = schoolClass&&schoolClass.filter(list=>list.ID===classID)
  let section = answer.map(c=>c.section)
  let sectionIndex = section.length!==0?section[0].filter(sec=>sec.ID===sectionID):[]
  const result = answer.map(c=>c.cname)
  const sectionname = sectionIndex.length!==0?sectionIndex[0].sname:[]
return classID===sectionID? result: sectionname
}

export const getAge =(dob)=>{
  if(dob!=='0000-00-00'){

  
  var dbirth = new Date(dob)
  var ageDiff = Date.now() - dbirth.getTime()
  var ageDate = new Date(ageDiff)
  return Math.abs(ageDate.getUTCFullYear()-1970)
}else{
  return ''
}
}

export const  compare=(a,b)=>{
  let result = 0;
  if(Number(a.order)>Number(b.order)){
      result = 1
  }else if (Number(a.order)<Number(b.order)){
      result = -1
  }
  return result
  }

export const convertDateTime=(date)=>{
  let result = ''
  if(date!=='0000-00-00 00:00:00'){
 result = new Date(date).toISOString().slice(0,19)
  }else{
    result =''
  }
  return result
}
export const convertDate=(date)=>{
let result = ''
if(date!=='0000-00-00'){
result = new Date(date).toISOString().slice(0,10)
}else{
  result =''
}
return result
}

export  const getWeekNumber=(date)=>{
  let now = new Date(date)
  let oneJan = new Date(now.getFullYear(), 0, 1);
  let week = Math.ceil((((now.getTime()-oneJan.getTime())/86400000)+oneJan.getDay()+1)/7)

  return Number(week)
}


export const getDays=(date)=>{
let day;
switch (new Date(date).getDay()){
    case 0:
        day ="Sunday";
        break;
    case 1:
        day ="Monday";
        break;
    case 2:
        day ="Tuesday";
       break;
    case 3:
        day ="Wednessday";
            break;
    case 4:
        day ="Thursday";
            break;
    case 5:
        day ="Friday";
        break;
    case 6:
        day ="Saturday"
        break;
    default:
        day="Sunday"
}
    return day
}
export  const getDateDifference=(from, to)=>{
var fromDate =  new Date(from);
var toDate =  new Date(to);
var diff_time = toDate.getTime()-fromDate.getTime()
var differ = diff_time/(1000*3600*24); 
return differ
}


export const sort=(a,b)=>{
  let result = 0;
  if(Number(a.order)>Number(b.order)){
      result = 1
  }else if (Number(a.order)<Number(b.order)){
      result = -1
  }
  return result
  }
  export const getRank=(i)=> {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return  "ST";
    }
    if (j == 2 && k != 12) {
        return  "ND";
    }
    if (j == 3 && k != 13) {
        return  "RD";
    }
    return  "TH";
}
  export const ordinalSurfix=(i)=> {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

  export  const longDate=(conv)=>{
    if(conv!==''&&conv!=='0000-00-00'){
    var options  = {year:'numeric', month:'long', day:'numeric'}
    var ndate = new Date(conv);
    return ndate.toLocaleDateString("en-GB", options)
    }else{
      return ''
    }
  }

  export const generateCardNo=(t)=>{
    t||(t=16);
    for(var e="",a=0;a<t;a++){
      var n=Math.floor(Math.random()*"1234567890".length);
      e+="1234567890".substring(n,n+1)
      }
     
      return e
  }
  
  export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
  
  export const getMonthYear=()=>{
    var months = ['January', 'February', 'March', 
                 'April', 'May', 'June', 'July', 
                 'August', 'September', 'October', 'November', 'December'];
    var date = new Date()
   var nDate =  months[date.getMonth()] +' '+ date.getFullYear()
   return nDate
  }
  
  export const getMonth=()=>{
    var months = ['January', 'February', 'March', 
                 'April', 'May', 'June', 'July', 
                 'August', 'September', 'October', 'November', 'December'];
    var date = new Date()
   var nDate =  months[date.getMonth()]
   return nDate
  }
   export const getTime =(value)=>{
    var timeString = String(value)
   var hourEnd = timeString.indexOf(":");
   var H = timeString.substr(0,2);
   var h = H % 12 || 12;
   var ampm =  (H<12 || H===24)?"AM":"PM";
    if (h < 10) h = "0" + h
   var result = h + timeString.substr(hourEnd, 3)+" "+ampm
  
  
  return result;
  }

  export const   getBritishDate=(dateString)=>{
    var months = ['January', 'February', 'March', 
    'April', 'May', 'June', 'July', 
    'August', 'September', 'October', 'November', 'December'];

var splitDate = dateString.split('-');
var month = Number(splitDate[1])-1; //Javascript months are 0-11
var surfix = getRank(splitDate[2]).toLowerCase()

let newDate = parseInt(splitDate[2])+surfix+ ' '+ months[month]+ ', '+splitDate[0]

return newDate
}



const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span style={{color:'black', fontSize:'15px',fontWeight: 'bold', }}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);