import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}




export const studentName = Cookies.get('pagestkstnm')?CryptoJS.AES.decrypt(Cookies.get('pagestkstnm'), 'pageschool').toString(CryptoJS.enc.Utf8):'null'
export const classID = Cookies.get('pagesclkysd')?CryptoJS.AES.decrypt(Cookies.get('pagesclkysd'), 'pageschool').toString(CryptoJS.enc.Utf8):'null'
export const sectionID = Cookies.get('pagessckayd')?CryptoJS.AES.decrypt(Cookies.get('pagessckayd'), 'pageschool').toString(CryptoJS.enc.Utf8):'null'

export const familyCode = Cookies.get('pagefmcdk')?CryptoJS.AES.decrypt(Cookies.get('pagefmcdk'), 'pageschool').toString(CryptoJS.enc.Utf8):'null'
export const studentCode= Cookies.get('pagestkauscd')?CryptoJS.AES.decrypt(Cookies.get('pagestkauscd'), 'pageschool').toString(CryptoJS.enc.Utf8):'null'
export const Token = Cookies.get('pagestkamtk')?CryptoJS.AES.decrypt(Cookies.get('pagestkamtk'), 'pageschool').toString(CryptoJS.enc.Utf8):'null'
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'




export  const ServerUrl = 'https://app.pageschoolsportal.org/controller';
export  const ImagesUrl = 'https://app.pageschoolsportal.org/images';
export  const StudentUrl = 'https://portal.pageschoolsportal.org';  
export  const LocalUrl = 'localhost';
export const schoolName = 'PAGE INTERNATIONAL SCHOOL' 

/* export  const ServerUrl = 'http://192.168.64.3/kayus/page/controller';
export  const ImagesUrl = 'http://192.168.64.3/kayus/page/images'; 
*/
//export  const LocalUrl = 'localhost';  


export  const PSKLIVE = 'pk_live_3d1e0b6826b44e63b467067c6036f41bc4edec1e'; 
export  const PSKTESTPublic = 'pk_test_69fe176f8520a2e60f1d40ec354e22f4c075163f';
 


