import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, Currency, studentCode, config, schoolName, studentName, ImagesUrl, PSKLIVE} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, FormatNumber } from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { usePaystackPayment } from 'react-paystack';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

const FeesPayment=()=>{
	const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
	
	const [feesTitle, setFeesTitle] =  useState('Fees Payment');
const [feetype, setFeetype] =  useState([]);
const [payment, setPayment] =  useState([]);
const [studentFee, setStudentFee]=useState({
	student:[],
	receiptNo:Math.random().toString(36).substr(2,9).toUpperCase(),
	paymentMethod:[],
	amountPaid:0,
	remark:'',
	paymentDate:'',
	
	installment:0
});
const [fees, setFees] = useState([]);
const [feeStructure, setFeeStructure] = useState([]);
const [student, setStudent] = useState([]);

var amountTopay = (Number(studentFee.installment)+ (0.016 * Number(studentFee.installment))+100) * 100
var txRef = (new Date()).getTime().toString();

const fetchStudent =()=>{
    var sql ="SELECT code, gender, admissionNumber, registerNumber, studentName, email, mobile,  motherEmail, fatherEmail from tbl_students where  code ='"+studentCode+"'"
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
	if(Array.isArray(result.data)){

		setStudent(result.data[0])
	}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
}

const handleChange =(event)=>{
	let {name, value} = event.target
	setStudentFee({...studentFee, [name]:value.replace(/[^0-9]/g, '')})
}

const getFees=(ID)=>{
	const rs = feetype.filter(item=>item.ID===ID)

	return rs.length!==0?rs[0].fees:''
}
const  handleFetchFeeType =()=>{
	const fd = new FormData();
			fd.append('jwt', Token);
	let url = ServerUrl + '/fetch_controller/tbl_fee_type'
	axios.post(url, fd, config)
	.then(result =>setFeetype(result.data))
	.catch((error)=>{
		Alerts('Error!', 'danger', JSON.stringify(error.message)) 
	})    
		}


const fetchPayment =()=>{
	setNotice({...notice, isLoading: true}) 
	var sql ="SELECT CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, h.code, h.amount,  h.receiptNo, h.status, h.paymentMode, h.datePaid, h.remark from tbl_fees_charge f,  tbl_fees_payment_history h where h.feesCharge = f.code and  h.studentCode ='"+studentCode+"'"

const fd = new FormData();
fd.append("jwt", Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
	
setPayment(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}


const fetchFees =()=>{
	setNotice({...notice, isLoading: true}) 
	var sql ="Select CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, s.code, s.feeCharge, s.total, (select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =s.code) as paid  from tbl_fees_charge f, tbl_student_fees s where s.feeCharge = f.code and s.studentCode = '"+studentCode+"' order by f.feesCharge" ;

	const fd = new FormData();
	fd.append("jwt", Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
setFees(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}
	
	const fetchFeestructure =(row)=>{
		setFeesTitle(row.fees)
		setStudentFee({...studentFee, installment:Number(row.total) - Number(row.paid)})
		setNotice({...notice, isLoading: true}) 
		var sql ="Select feeStructure, total, feeCharge, classID, sectionID from tbl_student_fees where code = '"+row.code+"'" ;
const fd = new FormData();
fd.append("jwt", Token);
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller'
	axios.post(url, fd, config).then(result=>{
		setFeeStructure({structure:JSON.parse(result.data[0].feeStructure), feeCharge:result.data[0].feeCharge, paid:row.paid, total:result.data[0].total, balance:Number(row.total)-Number(row.paid), code:row.code, classID:result.data[0].classID, sectionID:result.data[0].sectionID})
		structure.current.scrollIntoView({ behavior: "smooth" });
	})
	.catch((error)=>{
	Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, isLoading: false})
		
	})
	}

const handlePaymentReport=(result)=>{
			
			const fd = new FormData();




			fd.append('amount', studentFee.installment);
			fd.append('currency', 'NGN');
			fd.append('flw_ref', JSON.stringify(result, null, 2));
			fd.append('transaction_id ', result.transaction);
			fd.append('status', 'successful');
			fd.append('tx_ref', txRef);

            fd.append('jwt', Token);
			
			fd.append('remark', feesTitle);
			fd.append('customers', studentName);
			fd.append('code', Math.random().toString(36).substr(2,9));
				 let url = ServerUrl+'/save_controller/tbl_online_payment_report'
				axios.post(url, fd, config)
				.then(response =>{
		 			if (response.data.type ==='success') {
						//Alerts('Saved!', 'success', response.data.message)
					}  else{
						Alerts('Error!', 'danger', JSON.stringify(response.data))
				}   
						
				})
				.catch((error)=>{
					Alerts('Error!', 'danger', JSON.stringify(error.message)) 
				})
}


		 function submitPayment(ref){  
    
			var totalPaid = Number(feeStructure.paid) + studentFee.installment
			var status = totalPaid>=Number(feeStructure.total)?'PAID':'PARTIAL PAYMENT'
			
			const fd = new FormData();
			fd.append("jwt", Token);
			
			fd.append('code', Math.random().toString(36).substr(2,9));
			fd.append('status', status);
			fd.append('feesCharge', feeStructure.feeCharge);
			fd.append('scheduleCode', feeStructure.code);
			fd.append('studentCode', studentCode);
			fd.append('paymentMode', 'Online - Paystack');
			fd.append('receiptNo', ref);
			fd.append('classID', feeStructure.classID);
			fd.append('sectionID', feeStructure.sectionID);
			fd.append('amountPaid', studentFee.installment);
			fd.append('remark', 'successfull');
			fd.append('paidBy', studentName);
			fd.append('paymentDate', new Date().toISOString().slice(0,10));

			setNotice({...notice,  isLoading: true}) 
		
				 let url = ServerUrl+'/save_controller/tbl_fees_payment_history'
				axios.post(url, fd, config)
				.then(response =>{
		 			if (response.data.type ==='success') {
						Alerts('Saved!', 'success', response.data.message)
					}  else{
						Alerts('Error!', 'danger', JSON.stringify(response.data))
				}   
						
				})
				.catch((error)=>{
					Alerts('Error!', 'danger', JSON.stringify(error.message)) 
				})
				.finally(()=>{
					fetchFees()
					fetchPayment()
					setFeeStructure('')
				  setNotice({...notice, isLoading: false})  
				 setStudentFee({...studentFee,
					receiptNo:Math.random().toString(36).substr(2,9).toUpperCase(),
					paymentMethod:[],
					amountPaid:0,
					remark:'',
					paymentDate:''
				 })
				})   
				  
		}
		
	

	const tablePaymentHeader = [
		{dataField: 'code', text: 'Action', formatter:(cell, row)=><form method='POST' action={ServerUrl+'/printout/payment_receipt.php?token='+userToken} target='_blank'>
		
		<input type='hidden' name='slipcode' value ={cell} />
         <input type='hidden' name='jwtToken' value ={Token} />
		

		<button type='submit' className="btn btn-info btn-sm"  title='Print' > <i className="fa fa-print"></i> </button>
		</form>},
		{dataField: 'receiptNo', text: 'Receipt No'},
		{dataField: 'fees', text: 'Fees'},
		{dataField: 'status', text: 'Status',   formatter: (cell)=>cell==='PAID'?<span className="pcoded-badge label label-success">{cell}</span>:cell==='PARTIAL PAYMENT'? <span className="pcoded-badge label label-warning">{cell}</span>:<span className="pcoded-badge label label-danger">{cell}</span>},
		{dataField: 'paymentMode', text: 'Mode'},
		{dataField: 'amount', text: 'Amount ('+Currency+')',  formatter:(cell)=>FormatNumber(cell)},
		{dataField: 'remark', text: 'Remark', formatter:(cell)=><span style={{display:'block',  width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
		{dataField: 'datePaid', text: 'Date Paid', formatter:(cell)=>longDate(cell)}
		
	 ];

	

	 const TablePayment=(props)=>{
	   
	const { SearchBar } = Search;
	const CustomToggleList = ({
		columns,
		onColumnToggle,
		toggles
	  }) => ( 
	  <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
		  {
			columns
			  .map(column => ({
				...column,
				toggle: toggles[column.dataField]
			  }))
			  .map(column => (
				
				<button
				  type="button"
				  key={ column.dataField }
				  className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
				  data-toggle="button"
				  aria-pressed={ column.toggle ? 'true' : 'false' }
				  onClick={ () => onColumnToggle(column.dataField) }
				>
				  { column.text }
				</button>
			  ))
		  }
		</div>
		
	  );
	const customTotal = (from, to, size) => (
	<span >&nbsp;Showing { from } to { to } of { size } items</span>
	);
	const options = {
	showTotal: true,
	paginationTotalRenderer: customTotal,
	sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
	{ text: 'All', value: props.data.length }]
	
	};
	return  <ToolkitProvider search columnToggle 
				keyField='code' data={ props.data } columns={ props.columns } >
					
					{
						props => (
							<div >
						<div className="form-group">

					<SearchBar
								 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
								
		<div className="btn-group dropdown-split-success">
			
			<button type="button" className="btn btn-primary btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i className="fa fa-search-plus"></i> Columns
			</button>
			
			<div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'220px', width:'200px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
			<CustomToggleList { ...props.columnToggleProps } />
			</div>
			</div>
									
			<div className="dt-buttons btn-group pull-right"> 
				<form method='POST' action={ServerUrl+'/printout/student_payments_history.php?token='+userToken} target='_blank'>
		
				<input type='hidden' name='studentCode' value ={studentCode} />
				<input type='hidden' name='jwtToken' value ={Token} />

				<button type="submit" className="btn btn-outline-primary "  > <i className="fa fa-eye"></i>Print Preview
		</button>
		</form>
		
	</div>                   
	</div>

	<BootstrapTable
				{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					
				/>
			</div>
			)
					}
</ToolkitProvider>
}



  
const paystackConfig = {
reference: txRef,
email: student.fatherEmail!==''?student.fatherEmail:student.motherEmail,
amount: amountTopay,
publicKey: PSKLIVE 
};

const onSuccess = (ref) => {
	handlePaymentReport(ref)
	submitPayment(ref.transaction)
};

const onClose = () => {
// implementation for  whatever you want to do when the Paystack dialog closed.
//console.log('closed')
}

const handlePaystackPayment = usePaystackPayment(paystackConfig);

		 useEffect(()=>{
			fetchFees()
			fetchStudent() 
	fetchPayment()
	handleFetchFeeType()
		},[]);

        return ( <Template menu='Fees' submenu='Fees Payment'>
			
			{notice.isLoading ?<LoadEffect className="floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Fees Payment">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Fees Payment</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

				<div className="row  ">
							
		
	
	
							{fees.length!==0?
								<div className="col-sm-12">
								<div className="card z-depth-0">
									<div className="card-header">
										<h5>Fees List</h5>
										<div className="card-header-right">
											<ul className="list-unstyled card-option">
												<li><i className="feather icon-maximizes full-card"></i></li>
												<li><i className="feather icon-minus minimize-card"></i></li>
												<li><i className="feather icon-trash-2 close-card"></i></li>
											</ul>
										</div>
									</div>
									<div className="card-block">
							
							
											<div className="card z-depth-0">
											<div className="card-block">
									
											<div className="row">
											<div className="col-md-12 table-responsive"> 
											
										<table className="table table-bordered">
											<thead>
												<tr>
													<th>Fees</th>
													
													<th> Due Amount ({Currency})</th>
													<th>Paid Amount ({Currency})</th>
													<th> Balance ({Currency})</th>
													<th> Action</th>
												</tr>
											</thead>
											<tbody >
												{fees.length!==0?fees.map((dat, id)=>{
												return	<tr key={id}>
												<td>{dat.fees}</td>
												<td>{FormatNumber(dat.total)}</td>
												<td>{FormatNumber(dat.paid)}</td>
												<td>{FormatNumber(Number(dat.total) - Number(dat.paid))}</td>
												<td>
							
													<form method='POST' action={ServerUrl+'/printout/student_class_fees.php?token='+userToken} target='_blank'>
									<input type='hidden' name='studentCode' value ={studentCode} />
									<input type='hidden' name='jwtToken' value ={Token} />
									<input type='hidden' name='feescharge' value ={dat.fees} />
							
									<input type='hidden' name='feeCode' value ={dat.code} />
							
									{Number(dat.paid) < Number(dat.total)?<div><button type='button' onClick={()=>fetchFeestructure(dat)} className="btn btn-success btn-sm">Select</button> 
									
									<button type="submit" className="btn btn-inverse btn-sm ml-2"><i className='fa fa-print'></i> Print</button>
							 </div>:''}
							
									
							</form>			</td>
													</tr>
												}):[]}
											</tbody>
										</table>
										
										</div></div>
											
									</div></div>
							
							{feeStructure.length!==0?
							
							
									<div className="card z-depth-0">
											<div className="card-block">
									
											<div className="row">
											<div className="col-md-12 table-responsive"> 
											
										<table className="table table-bordered">
										<thead>
												<tr>
													<th>Fees Type</th>
													<th>Amount ({Currency})</th>
													<th>Discount ({Currency})</th>
													<th>To Pay ({Currency})</th>
												</tr>
											</thead>
											<tbody >
										{feeStructure.structure.map((fee, idx)=> {
											return  Number(fee.price)>0? <tr key={idx}>
													<td>{getFees(fee.ID)}</td>
													<td>{FormatNumber(fee.price)}</td>
													<td>{FormatNumber(fee.discountAmount)}</td>
													<td>{FormatNumber(fee.discount)}</td>
												</tr>:[]
											})}
											</tbody>
											
											<tfoot>
												<tr>
													<th className='text-right' colSpan={3}>Total</th>
													<th>{FormatNumber(feeStructure.total)}</th>
												</tr>
												<tr>
													<th className='text-right' colSpan={3}>Amount to Pay</th>
													<th >{FormatNumber(feeStructure.balance)}</th>
												</tr>
												<tr>
				<th className='text-right' colSpan={3}>Installment Amount</th>
				<th ><input type='text' name="installment" className='form-control' pattern='[0-9]' onChange={handleChange} value={studentFee.installment} /></th>
			</tr>
											</tfoot>
										</table>
										
										</div></div>
											
									</div></div>:''}
							
							
							
									<div ref={structure} />
							
									<hr/>
							
									{feeStructure.length!==0?
							<footer className="">
							<form method='POST' action={ServerUrl+'/printout/student_fees_voucher.php?token='+userToken} target='_blank'>
									
									<input type='hidden' name='studentCode' value ={studentCode} />
									<input type='hidden' name='jwtToken' value ={Token} />
									<input type='hidden' name='feecharge' value ={feeStructure.feeCharge} />
									<button type="submit" id="submit" className="btn btn-inverse pull-left" > <i className="fa fa-bank"></i> Pay In Bank</button>
									
							</form>
							
							&nbsp;
							
							<button type="button" id="button" className="btn btn-success " onClick={() =>  handlePaystackPayment(onSuccess, onClose)
										}><i className="fa fa-credit-card"></i> Pay Online Now {Currency+FormatNumber(amountTopay/100)}</button>
							</footer>:''}
										</div>
										</div> 
											</div>:''}
							
							
											</div>
							
							{payment.length!==0?
											<div className="row">
							
											<div className="col-sm-12">
								<div className="card z-depth-0">
									<div className="card-header">
										<h5>Paid List</h5>
										<div className="card-header-right">
											<ul className="list-unstyled card-option">
												<li><i className="feather icon-maximizes full-card"></i></li>
												<li><i className="feather icon-minus minimize-card"></i></li>
												<li><i className="feather icon-trash-2 close-card"></i></li>
											</ul>
										</div>
									</div>
									<div className="card-block">
							
							
									<div className="col-md-12 table-responsive">
								
									<TablePayment data={payment}  columns={tablePaymentHeader} />
											</div>
										</div>
										</div> 
											</div></div>:''}


          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</Template> 
 );
        
}

export default React.memo(FeesPayment) 