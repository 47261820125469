import React, {useEffect} from 'react';
import {Alerts} from './component/notify'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import ViewTimetable from './timetable/view_timetable'
import LessonPlan from './timetable/lesson_plan'
import Holidays from './timetable/holidays_list'


import eLibrary from './library/e-library'
import BookReturn from './library/issues_return'
import Books from './library/books'



import StudentAttendance from './attendance/display_student_attendance'
import StudentArrival from './attendance/student_arrival_departure_late'

import ExamSchedule from './exam/exam_schedule'
import Transcript from './exam/transcript'


import FamilyFees from './fees/family_fees'
import FeesPayment from './fees/fees_payment'


import Dashboard from './dashboard/dashboard'

import Profile from './student/profile'
import Noticeboard from './student/noticeboard'
import Review from './student/review'
import Error404 from './settings/error404';


import axios from 'axios'
import { SessionTimeout } from './component/notify'
import Cookies from 'js-cookie'
import {config,  familyCode, classID, sectionID, studentName, Token,  ServerUrl, studentCode} from './component/include'
import {encrypt} from './component/authentication'
import hostel from './hostel/hostel';
import room_occupant from './hostel/room_occupant';
import print_mid_term_result from './exam/print_mid_term_result';
import print_class_result from './exam/print_class_result';


const ProtectedView =()=>{



const openWarningModal =(interver)=>{
      let openWarning =   document.getElementById('openTimeoutModal')
              openWarning.click();
              clearInterval(interver)
}
 

const handleReconnect =()=>{

  let closeWarning =   document.getElementById('btnLogoutsession')
  closeWarning.click();
           
const fd = new FormData();

fd.append('code', studentCode)
  fd.append('studentName', studentName)
  fd.append('classID', classID)
  fd.append('sectionID', sectionID)
  fd.append('familyCode', familyCode)
fd.append('jwt', Token)


    let url = ServerUrl+'/login_refresh_student_controller.php?tablename=tbl_refresh_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){

          //1000 * 60 * SESSION_IDEL_MINUTES
         
		var inSixtyMinutes = Date.now() + 4199 * 60 * 1000;
		var inOneMinutes = Date.now() + 418 * 60 * 1000;
		var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
    

		Cookies.set('_pagestexpt', inSixtyMinutes,  {expires: inOneHours })
		Cookies.set('_pagestbexpt', inOneMinutes,  {expires: inOneHours })

  Cookies.set('pagestkauscd', encrypt(response.data.code), {expires: inOneHours })
  Cookies.set('pagestkamtk', encrypt(response.data.jwt),  {expires: inOneHours })   
  Cookies.set('pagestkarfsh', encrypt(response.data.userToken),  {expires: inOneHours })
  Cookies.set('pagestkstnm', encrypt(response.data.studentName),  {expires: inOneHours })

  Cookies.set('pagefmcdk', encrypt(response.data.familyCode),  {expires: inOneHours })

  Cookies.set('pagesclkysd', encrypt(response.data.classID),  {expires: inOneHours })
  Cookies.set('pagessckayd', encrypt(response.data.sectionID),  {expires: inOneHours })

      Alerts('Info!', 'success', 'Successfuly reconnected')

        window.location.reload()
             } else {
				handleLogout()
			}  
       

		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
     handleLogout()
		})
}



const handleLogout =()=>{
Alerts('Info!', 'default', 'Your session has expired')
window.location.href='/?refferer='+window.location.href
}


const startWarning = ()=>{
  var interver;
  interver = setInterval(() => {
    //one minute before expiry time
 var beforeExpired = Number(Cookies.get('_pagestbexpt'))
  if(beforeExpired < Date.now()){
    openWarningModal(interver)
//timeout in one minutes time
  }
}, 5000)
}
  
     
useEffect(()=>{
  if (localStorage.getItem("pagestlcksr") !== null) {
    document.getElementById('openlockscreenModal').click()
 }
})


useEffect(()=>{
  //fetchContent()
startWarning()
   
   setInterval(() => {
 var expiredTime = Number(Cookies.get('_pagestexpt'))
  if(expiredTime < Date.now()){
    handleLogout()
    //timeup logout user
  }
}, 5000)
}, [])

    return ( <>
    <a href="#!" data-toggle="modal" id='openTimeoutModal' data-target='#timeoutModal' ></a>
    <SessionTimeout  Logout={handleLogout} Connected ={handleReconnect} /> 
    
    <Router  >
      <Switch>


      <Route exact path="/dashboard" component={Dashboard} />
    
    <Route exact path="/timetable/class_timetable" component={ViewTimetable} /> 
    <Route exact path="/timetable/class_lesson_plan" component={LessonPlan} /> 
    <Route exact path="/timetable/holidays" component={Holidays} /> 
    
    
    <Route exact path="/library/elibrary" component={eLibrary} /> 
    <Route exact path="/library/books_report" component={BookReturn} /> 
    <Route exact path="/library/books" component={Books} />
    
    <Route exact path="/attendance/student_attendance" component={StudentAttendance} /> 
    <Route exact path="/attendance/student_arrival_departure" component={StudentArrival} /> 
    
    <Route exact path="/exam/exam_schedule" component={ExamSchedule} />  
    <Route exact path="/exam/mytranscript" component={Transcript} /> 

    <Route exact path="/exam/mid_term" component={print_mid_term_result} /> 

    <Route exact path="/exam/myresult" component={print_class_result} />

    {/* 
    <Route exact path="/exam/mock_result" component={MockResult} />  */}

    <Route exact path="/fees/family_fees" component={FamilyFees} /> 
    <Route exact path="/fees/fees_payment" component={FeesPayment} /> 
    
    
    <Route exact path="/student/profile" component={Profile} /> 
    <Route exact path="/student/review" component={Review} /> 
    <Route exact path="/student/noticeboard" component={Noticeboard} /> 
   {/*  <Route exact path="/student/change_password" component={ChangePassword} />  */}


    <Route exact path="/hostel/allocation" component={hostel} /> 
    <Route exact path="/hostel/history" component={room_occupant} /> 
        <Route exact  component={Error404} />
      </Switch>
      </Router>
      </>
    );
  
}
export default ProtectedView;
